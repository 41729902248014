/* warning.js */
export const ERROE_ARR = 'ERROE_ARR'   //答错题目数组
export const RIGHT_ARR = 'RIGHT_ARR' //打对题目数组
export const QST_ARR = 'QST_ARR'   //题库信息
export const GRADES = 'GRADES'    //答题信息
export const QST_INDEX = 'QST_INDEX'   //当前题目的下标
export const QST_INFO = 'QST_INFO'   //当前题目的信息
export const BALL = 'BALL'   //真实考场模拟答题情况
export const TIMESTAMP = 'TIMESTAMP'  //真实考场模拟倒计时

export const ANSWER_ERR = 'ANSWER_ERR'  //顺序练习错题
export const ANSWER_RIGHT = 'ANSWER_RIGHT'  //顺序练习对题
export const ANSWER_ARR = 'ANSWER_ARR'    //顺序练习题库
export const SERIAL = 'SERIAL'  //顺序答题情况
export const ANSWER_INFO = 'ANSWER_INFO'  //顺序练习题库信息
export const ANSWER_INDEX = 'ANSWER_INDEX'  //下标

/* recruit.js */
export const CHOICETIP = 'CHOICETIP' 
export const CD_CAOCH_TIP = 'CD_CAOCH_TIP'
/* share.js */
export const SET_USERINFO = 'SET_USERINFO'   //设置全局用户信息
export const WXCONFIG = 'WXCONFIG'  //微信授权
export const PAGE_ENTER = 'PAGE_ENTER'   //页面拦截
export const GETLOCATIONS = 'GETLOCATIONS'    //获取位置信息
export const CITY_CODE = 'CITY_CODE'   //城市二维码
export const WXSHARE = 'WXSHARE'     //微信分享
 
export const HOMEPAGE = 'HOMEPAGE'   //主页信息