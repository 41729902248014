import Vue from 'vue'
import Vuex from 'vuex'

import user from './module/user'
import warning from './module/warning.js'
import share from './module/share'
import recruit from './module/recruit'
import answer from './module/answer'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //
  },
  mutations: {
    //
  },
  actions: {
    //
  },
  modules: {
    user,
    warning,
    share,
    recruit,
    answer
  }
})
