import { getSNSUserInfo, getAppid, getAuUserInfo } from '@/api/weixin'
import { getInfo, bindUser, kmModule, handlePost } from '@/api/user'
import { locationAddress, topicCity, mumberInfo } from '@/api/theory'
import { setToken, getToken } from '@/libs/util'
import { wxJsConfig } from '@/api/weixin';
import wx from 'weixin-js-sdk';
import Vue from 'vue'
import Vconsole from 'vconsole'

export default {
  state: {
    userInfo: {
      s_city_title: "",
      km1_city: ""
    },
    km1_show: '',
    km3_show: '',
    km_type: localStorage.getItem("km_type") || -1,
    token: getToken(),
    wxJsConfig: {},
    address: {},
    city_name: "",
    isIos: 0,     // 是否IOS端
    is_new: 0,    // 是否新用户
    shareHiddenInfo: false,
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    setToken(state, token) {
      state.token = token
      setToken(token)
    },
    setKmModel(state, kmInfo) {
      state.km1_show = kmInfo.km1
      state.km3_show = kmInfo.km3
    },
    setNewUser(state, newUse) {
      state.is_new = newUse
    },
    setKm1City(state, name){
      state.city_name = name
      localStorage.setItem('CITY_NAME', name)
    }
  },
  actions: {
    //获取授权code的url连接
    getOauthUrl({ commit }, { baseUrl, scope }) {
      return new Promise((resolve, reject) => {
        getAppid(baseUrl, scope).then(res => {
          if (res.code === 200) {
            sessionStorage.setItem('appid', res.data.data)
          }
          resolve(res)
        })
      })
    },
    
    //根据授权code获取用户信息
    getSNSUserInfo({ commit }, { code, scope }) {
      return new Promise((resolve, reject) => {
        getSNSUserInfo(code, scope).then(res => {
          //通过区代页面跳转进来的，用户身份转换为区代
          if (res.code === 200) {
            if (res.data.info.is_debug != 0) {
              new Vconsole();
            }
            let regional = sessionStorage.getItem('is_regional') || 0
            if (regional && res.data.info.is_regional == 1) {
              res.data.info.user_type = 3
            }
            commit('setUserInfo', res.data.info)
            commit('setToken', res.data.token)
            let time = Math.round(new Date().getTime() / 1000)
            sessionStorage.setItem('user_login_time', time);
            sessionStorage.setItem("user_type", res.data.info.user_type)
          }
          resolve(res)
        })
      })
    },

    // 根据授权code获取 是否是静默或者非静默登录 state: 0 是,  1 否  改版
    getAuUserInfo({ commit }, { code, scope, state }) {
      return new Promise((resolve, reject) => {
        getAuUserInfo(code, scope, state).then(res => {
          //通过区代页面跳转进来的，用户身份转换为区代
          if (res.code === 200) {
            if (res.data.info.is_debug != 0) {
              new Vconsole();
            }
            let regional = sessionStorage.getItem('is_regional') || 0
            if (regional && res.data.info.is_regional == 1) {
              res.data.info.user_type = 3
            }
            commit('setUserInfo', res.data.info)
            commit('setToken', res.data.token)
            let time = Math.round(new Date().getTime() / 1000)
            sessionStorage.setItem('user_login_time', time);
            sessionStorage.setItem("user_type", res.data.info.user_type)
          }
          resolve(res)
        })
      })
    },

    // 获取用户相关信息
    getUserInfo({ state, commit }) {
      let time = Math.round(new Date().getTime() / 1000)
      let st = sessionStorage.getItem('user_login_time')
      let at = time - st
      if (JSON.stringify(state.userInfo) !== '{}' && at < 2592000) {
        return state.userInfo
      } else {
        return new Promise((resolve, reject) => {
          try {
            getInfo().then(res => {
              //通过区代页面跳转进来的，用户身份转换为区代
              if (res.code === 200) {
                if (res.data.member.is_debug != 0) {
                  new Vconsole();
                }
                state.km1_show = Vue.prototype.$config.km1_show
                state.km3_show = Vue.prototype.$config.km3_show
                let regional = sessionStorage.getItem('is_regional') || 0
                if (regional && res.data.member.is_regional == 1) {
                  res.data.member.user_type = 3
                }
                commit('setUserInfo', res.data.member)
                commit('setToken', res.data.token)
                sessionStorage.setItem('user_login_time', time);
                sessionStorage.setItem('user_type', res.data.member.user_type)
              }
              resolve(res.data.member)
            }).catch(err => {
              reject(err)
            })
          } catch (error) {
            reject(error)
          }
        })
      }
    },
    bindSuper({ dispatch, state }, { shareMember, shareType }) {
      if (sessionStorage.getItem('is_bind_share')) {
        return;
      }
      return new Promise((resolve, reject) => {
        try {
          sessionStorage.setItem('is_bind_share', 1)
          shareMember = shareMember.split(",")[0]
          bindUser({ super_id: shareMember, type: shareType }).then(res => {
            if (res.code === 200 && shareType == 2) {
              state.userInfo = {}
              dispatch('getUserInfo').then(res => {
                resolve()
              })
            } else {
              reject()
            }
          }).catch(err => {
            reject(err)
          })
        } catch (error) {
          reject(error)
        }
      })
    },
    getWxConfig({ state, commit }, { name }) {
      if (state.isIos == 1) {
        return
      }
      let time = Math.round(new Date().getTime() / 1000)
      let key = 'wx_config_create_time_' + name
      let st = sessionStorage.getItem(key)
      let at = time - st
      if (JSON.stringify(state.wxJsConfig) !== '{}' && at < 3600) {
        return;
      }
      let url, u, isiOS;
      u = navigator.userAgent;
      isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (sessionStorage.getItem('init_sign') == 1 && isiOS) {
        url = sessionStorage.getItem('init_url') 
        sessionStorage.setItem('init_sign',2)
      } else {
        url = window.location.href
      }

      wxJsConfig({ url: url }).then(res => {
        // sessionStorage.setItem(key, time);
        state.wxJsConfig = res.data
        wx.config({
          beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端弹框出来
          appId: res.data.appId, // 必填，企业微信的corpID
          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.nonceStr, // 必填，生成签名的随机串 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'getLocation', 'showMenuItems', 'chooseWXPay', 'onMenuShareAppMessage', 'onMenuShareTimeline', 'closeWindow', 'hideMenuItems', 'scanQRCode']
        });
        wx.ready(function () {
          wx.hideMenuItems({
            menuList: ["menuItem:copyUrl", "menuItem:exposeArticle", "menuItem:editTag", 'menuItem:share:qq', "menuItem:share:QZone",]
          });
          wx.showMenuItems({
            menuList: ['menuItem:share:appMessage', 'menuItem:share:timeline']
          })
        })

        if (isiOS) {
          state.isIos = 1
        }
      })
    },
    wxShare({ state, commit }, { shareUrl, config }) {
      wx.ready(function () {
        //分享给朋友
        wx.updateAppMessageShareData({
          title: config.title,
          desc: config.content,
          link: shareUrl,
          imgUrl: config.imgurl,
          success: function () { },
        });

        //分享到朋友圈
        wx.updateTimelineShareData({
          title: config.title,
          link: shareUrl,
          imgUrl: config.imgurl,
          success: function () { },
        });
      })

      wx.error(function (err) {
        let url = window.location.href
        if (sessionStorage.getItem('share_url_is_reload:' + url) != 1) {
          sessionStorage.setItem('share_url_is_reload:' + url, 1)
          window.location.replace(url)
        }
      })
    },
    getLocation({ state, commit }) {
      let is_addres = localStorage.getItem('is_addres') || false,
          day_address = localStorage.getItem('day_address') || 0,
          day_sart = new Date().getTime();
      let is_show = false, params, addInfo;
      if (is_addres && day_address - day_sart > 0) {
        return
      }
      wx.ready(() =>{
        if (!is_show) {
          is_show = true;
          wx.getLocation({
            type: 'gcj02',
            success: resp =>{
              // GPS坐标转腾讯
              let latitude = resp.latitude; // 纬度，浮点数，范围为90 ~ -90
              let longitude = resp.longitude; // 经度，浮点数，范围为180 ~ -180。
              let site, city_name;
              new qq.maps.Geocoder({
                complete:function(result){
                  let addComp = result.detail.addressComponents;
                  site = result.detail.address
                  city_name = result.detail.addressComponents.city;
                  state.address = addComp
                }
              });
              localStorage.setItem('is_addres', false);
              var geolocation = new qq.maps.Geolocation(
                'I7RBZ-BJNCP-ZRMDE-LYL27-Q6RV5-H4BCX',
                "myapp"
              );
              // 回调的第一次参数为成功后具体的位置信息
              geolocation.getLocation(
                postion => {
                  city_name = city_name ? city_name : postion.city;
                  addInfo = postion.nation + postion.province + postion.city + postion.addr;
                  localStorage.setItem('default_adcode', postion.adcode)
                  params = {
                    longitude: latitude,
                    latitude: longitude,
                    address: site || addInfo,
                    adcode: postion.adcode
                  }
                  if (!localStorage.getItem('default_city_name')) {
                    localStorage.setItem('default_city_name', city_name)
                    topicCity().then(res =>{
                      res.data.map(item => {
                        if (item.title == city_name) {
                          localStorage.setItem('default_city_id', item.id)
                          return
                        }
                      })
                    })
                  }
                  locationAddress(params)
                },
                err => {
                  // alert("获取腾讯解析地址失败"+JSON.stringify(err));
                }
              );
            },
            fail: (err) => {
              // alert(JSON.stringify(err)+'获取定位失败')
            },
            complete: (reComp) => {
              if (reComp.errMsg == "getLocation:cancel") {
                localStorage.setItem('is_addres', true)
                day_address = new Date(
                  new Date(new Date().toLocaleDateString()).getTime() +
                    24 * 60 * 60 * 1000 -
                    1
                ).getTime();
                localStorage.setItem('day_address', day_address);
                params = {
                  longitude: "",
                  latitude: "",
                  address: "用户已拒绝获取定位",
                  adcode: ""
                }
                locationAddress(params)
              }
            }
          })
        }
      })
      wx.error(() => {
        console.error('wx调用错误 ');
      })
    },

    // 获取是否是内测人员
    getKMModel({ state, commit }) {
      return new Promise((resolve, reject) => {
        try {
          kmModule().then(res => {
            if (res.code === 200) {
              commit('setKmModel', res.data.data)
            }
            resolve(res.data.data)
          }).catch(err => {
            reject(err)
          })
        } catch (error) {
          reject(error)
        }
      })
    },

    // 获取是否隐藏分享个人信息
    getOrSetShareHidInfo({ state } , { val }) {
      let data = { type:  val}
      return new Promise((resolve, reject) => {
        try {
          handlePost(data).then(res => {
            if (res.code == 200) {
              if (val != 0) {
                state.shareHiddenInfo =  val == -1 ? true : false
              } else {
                state.shareHiddenInfo = res.data.show == -1 ? true : false
              }
            }
          }).catch(err => {
            reject(err)
          })
        } catch (error) {
          reject(error)
        }
      })
    },

    // 获取用户信息接口
    getAllUserInfo({state, commit}) {
      return new Promise((reslove,reject) => {
        mumberInfo().then(res =>{
          if(res.code == 200) {
            let regional = sessionStorage.getItem('is_regional') || 0
            if (regional && res.data.info.is_regional == 1) {
              res.data.info.user_type = 3
            }
            if (res.data.info.s_city != 0 && !localStorage.getItem('default_city_id')) {
              localStorage.setItem('default_city_id', res.data.info.s_city)
              let ad = res.data.info.s_city_title == "市辖区" ? '上海市' : res.data.info.s_city_title
              localStorage.setItem('default_city_name', ad)
            }
            commit('setUserInfo', res.data.info)
            commit('setKm1City', res.data.info.km1_city)
            reslove(res.data.info)
          }
        })
      })
    },
  }
}
