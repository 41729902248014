import * as types from '../type.js'
const  state = {
	qst_arr: [],     //题库
	qst_info: {},       //当前题目的信息
	serial_number: {}, //顺序答题情况
	qst_index: null,    //当前题目下标
}

const mutations = {
	[types.ANSWER_ARR](state,qst_arr) {
		// state.qst_arr = qst_arr
		// sessionStorage.setItem('answer_qst_arr',JSON.stringify(state.qst_arr))
	},
	[types.ANSWER_INFO](state,qst_info) {
		state.qst_info = qst_info
		sessionStorage.setItem('answer_qst_info',JSON.stringify(state.qst_info))
	},
	[types.SERIAL] (state,serial_number) {
		state.serial_number = serial_number
		localStorage.setItem("zskc_serial_number",JSON.stringify(state.serial_number))
	},
	[types.ANSWER_INDEX] (state,qst_index) {
		state.qst_index = qst_index
		localStorage.setItem('zskc_qst_index',JSON.stringify(state.qst_index))
	}
}

const actions = {
	
}

export default {
	state,
	mutations,
	actions
}