import Vue from 'vue'
import Router from 'vue-router'
import routes from './routers'
import { getToken, setTitle } from '@/libs/util'
import store from '@/store'
import { Notify } from 'vant';

Vue.use(Router)
const router = new Router({
  mode: 'history',
  routes
})

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 防止连续点击多次路由报错
// let originPush =  Router.prototype.push;  //备份原push方法
// Router.prototype.push = function (location, resolve, reject){
//   if (resolve && reject) {    //如果传了回调函数，直接使用
//     originPush.call(this, location, resolve, reject);
//   }else {                     //如果没有传回调函数，手动添加
//     originPush.call(this, location, ()=>{}, ()=>{}); 
//   }
// }

router.beforeEach((to, from, next) => {

  let token = getToken();

  if (to.query.share_city_id && (!localStorage.getItem('default_city_id') || localStorage.getItem('default_city_id') == 0)) {
    localStorage.setItem('default_city_id', to.query.share_city_id)
    to.query.share_city_id = ""
  }

  if (to.query.share_city_name && !localStorage.getItem('default_city_name')) {
    localStorage.setItem('default_city_name', to.query.share_city_name)
    store.commit('setKm1City', to.query.share_city_name)
    to.query.share_city_name = ""
  }

  function checkShareMember() {
    // if (!localStorage.getItem('shareMember')) return alert('错误 没有找到本地存储的shareMeber')
    let myShareMember = localStorage.getItem('shareMember')
    if (to.query.shareMember && to.query.shareMember == myShareMember) {
      next()
    } else {
      let query = to.query
      if (myShareMember) {
        query.shareMember = myShareMember
        next({
          path: to.path,
          query: query
        })
      } else {
        next()
      }
    }
  }

  if (token) {
    if (to.meta.access) {
      var type = sessionStorage.getItem('user_type') * 1
      if (to.meta.access.indexOf(type) >= 0) {
        checkShareMember()
      } else {
        Notify('没有访问权限!')
        if (type == 3) {
          setTimeout(function () {
            WeixinJSBridge.call('closeWindow');
          }, 1000)
        } else {
          next({ path: '/' })
        }
      }
    } else {
      checkShareMember()
    }
  }
})

router.afterEach(to => {
  // 微信全局授权
  
  if (process.env.NODE_ENV != "development") {
    store.dispatch('getWxConfig', { name: to.name })
  }
  
  // 如果是通过分享进来的
  // if (to.query.shareMember) {
  //     let shareType = to.query.shareType || 1
  //     store.dispatch('bindSuper', { shareMember: to.query.shareMember, shareType: shareType })
  // }
  // if (localStorage.getItem('shareMember')) {
  //     // 取分享id
  //     let myShareMember = localStorage.getItem('shareMember')

  //     function updateQueryStringParameter(uri, key, value) {
  //         if (!value) {
  //             return uri;
  //         }
  //         var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  //         var separator = uri.indexOf('?') !== -1 ? "&" : "?";
  //         if (uri.match(re)) {
  //             return uri.replace(re, '$1' + key + "=" + value + '$2');
  //         }
  //         else {
  //             return uri + separator + key + "=" + value;
  //         }
  //     }                
  //     var newurl = updateQueryStringParameter(window.location.href, 'shareMember', myShareMember);
  //     // 向当前url添加参数，没有历史记录
  //     window.history.replaceState({}, '', newurl);
  //     // window.history.pushState({}, '', newurl);

  //     // const query = JSON.parse(JSON.stringify(to.query))
  //     // query.shareMember = myShareMember
  //     // router.push({ path: to.path, query })
  // }
  setTitle(to, router.app)
})

export default router
