import Main from '@/components/tabbar'

export default [
	{
	  path: '/',
    name: 'index',
    redirect: '/index',
    component: Main,
    children: [
      { path: '/index',
        name: 'home',
        meta: {title: '首页', tabbar: true },
        component: () => import('@/view/index')
      },
      { path: '/myLesson',
        name: 'myLesson',
        meta: {title: '视频课程', tabbar: true},
        component: () => import('@/view/lessons/myLessons')
      },
      { path: '/profit',
        name: 'profit',
        meta: {title: '我的收益', access: [2,3], tabbar: true},
        component: () => import('@/view/profit/profit')
      },
      { path: '/info',
        name: 'info',
        meta: {title: '个人中心', tabbar: true},
        component: () => import('@/view/info')
      },
      { path: '/share',
        name: 'share',
        meta: {title: '课程分享'},
        // component: () => import('@/view/share')
        component: () => import('@/view/newShare')
      },
      { path: '/lessonInfo',
        name: 'lessonInfo',
        meta: {title: '课程详情'},
        component: () => import('@/view/lessons/lessonInfo')
      },
      { path: '/comment',
        name: 'comment',
        meta: {title: '评论'},
        component: () => import('@/view/lessons/Comment')
      },
      { path: '/Address',
        name: 'Address',
        meta: {title: '城市列表'},
        component: () => import('@/view/address')
      },
      { path: '/Wish',
        name: 'Wish',
        meta: {title: '许愿树'},
        component: () => import('@/view/wish')
      },
      { path: '/cash',
        name: 'cash',
        meta: {title: '提现申请', access: [2,3]},
        component: () => import('@/view/profit/cash')
      },
      { path: '/cashNow',
        name: 'cashNow',
        meta: {title: '立即提现', access: [2,3]},
        component: () => import('@/view/profit/cashNow')
      },
      { path: '/cashList',
        name: 'cashList',
        meta: {title: '提现列表', access: [2,3]},
        component: () => import('@/view/profit/cashList')
      },
      {
        path: '/profit_total',
        name: 'profit_total',
        meta: {title: '我的收益', access: [2,3]},
        component: () => import('@/view/profit/profit_total')
      },
      {
        path: '/Modify',
        name: 'Modify',
        meta: {title: '用户认证'},
        component: () => import('@/view/userInfo/Modify')
      },
      {
        path: '/Phone',
        name: 'Phone',
        meta: {title: '用户认证'},
        component: () => import('@/view/userInfo/Phone')
      },
      {
        path: '/personCert',
        name: 'personCert',
        // meta: {title: '个人认证'},
        component: () => import('@/view/userInfo/personCert')
      },
      {
        path: '/orders',
        name: 'orders',
        meta: {title: '用户订单'},
        component: () => import('@/view/userInfo/orders')
      },
      {
        path: '/Code',
        name: 'Code',
        meta: {title: '我的二维码'},
        component: () => import('@/view/userInfo/Code')
      },
      {
        path: '/Invite',
        name: 'Invite',
        meta: {title: '邀请下级代理', access: [2,3]},
        component: () => import('@/view/userInfo/Invite')
      },
      {
        path: '/addLessons',
        name: 'addLessons',
        meta: {title: '添加课程', access: [2,3]},
        component: () => import('@/view/lessons/addLessons')
      },
      {
        path: '/collAdd',
        name: 'CollectAdd',
        component: () => import('@/view/collect/add'),
        meta: {
          title: '收藏课程',
        },
      },
      {
        path: '/c_collect',
        name: 'Collect',
        component: () => import('@/view/collect'),
        meta: {
          title: '收藏课程',
        },
      },
      {
        path: '/line',
        name: 'line',
        meta: { title: '线路图列表',access: [2,3], tabbar: false},
        component: () => import('@/view/lessons/Line')
      },
      {
        path: '/c_LinelistSwitchCoach',
        name: 'LinelistSwitchCoach',
        meta: { title: '线路图教练切换'},
        component: () => import('@/view/lessons/LinelistSwitchCoach')
      },
      {
        path: '/LineList',
        name: 'LineList',
        meta: { title: '合集线路图'},
        component: () => import('@/view/lessons/LineList')
      },
      {
        path: '/downLine',
        name: 'downLine',
        meta: {title: '下载线路图'},
        component: () => import('@/view/lessons/downLine')
      },
      {
        path: '/register',
        name: 'register',
        meta: {title: '注册分销员'},
        component: () => import('@/view/userInfo/Phone')
      },
      {
        path: '/exam',
        name: 'exam',
        // meta: {title: '免费观看审核', access: [3]},
        component: () => import('@/view/exam')
      },
      {
        path: '/course',
        name: 'course',
        // meta: {title: '二维码课程列表', access: [1,2,3], tabbar: true},
        component: () => import('@/view/lessons/course')
      },
      {
        path: '/codeLst',
        name: 'codeLst',
        meta: {title: '我的二维码'},
        component: () => import('@/view/userInfo/codeLst')
      },
      {
        path: '/codePop',
        name: 'codePop',
        meta: {title: '二维码详情'},
        component: () => import('@/view/userInfo/codePop')
      },
      {
        path: '/permit',
        name: 'permit',
        meta: {title: '营业执照'},
        component: () => import('@/view/userInfo/permit')
      },
      {
        path: '/billPush',
        name: 'billPush',
        meta: {title: '账单推送'},
        component: () => import('@/view/userInfo/billPush')
      },
      {
        path: '/punchIn',
        name: 'punchIn',
        meta: {title: '打卡'},
        component: () => import('@/view/userInfo/punchIn')
      },
      {
        path: '/spreadLst',
        name: 'spreadLst',
        meta: {title: '地推二维码'},
        component: () => import('@/view/userInfo/spreadLst')
      },
      {
        path: '/eStatistics',
        name: 'eStatistics',
        meta: {title: '考场数据列表'},
        component: () => import('@/view/profit/eStatistics')
      },



      // ==================== 以下是科一科四路由 ==================== //
      {
        path: '/drivingInfo',
        name: 'drivingInfo',
        meta: {title: '驾考信息'},
        component: () => import('@/view/info/DrivingInfo')
      },
      {
        path: '/TopicSelect',
        name: 'TopicSelect',
        meta: {title: '精选题'},
        component: () => import('@/view/info/TopicSelect')
      },
      {
        path: '/warning',
        name: 'warning',
        meta: {title: '答题模式'},
        component: () => import('@/view/info/warning')
      },
      {
        path: '/enhancedQuestion',
        name: 'enhancedQuestion',
        meta: {title: '答题模式'},
        component: () => import('@/view/info/enhancedQuestion')
      },
      {
        path: '/answer',
        name: 'answer',
        meta: {title: '答题模式'},
        component: () => import('@/view/info/answer')
      },
      {
        path: '/choiceness',
        name: 'choiceness',
        meta: {title: '答题模式'},
        component: () => import('@/view/info/choiceness')
      },
      {
        path: '/icons',
        name: 'icons',
        meta: {title: '图标讲解'},
        component: () => import('@/view/info/icons')
      },
      {
        path: '/traffic',
        name: 'traffic',
        meta: {title: '图标讲解'},
        component: () => import('@/view/info/traffic')
      },
      {
        path: '/gread',
        name: 'gread',
        meta: {title: '图标讲解'},
        component: () => import('@/view/info/gread')
      },
      {
        path: '/start',
        name: 'start',
        meta: {title: '真实考场模拟'},
        component: () => import('@/view/real_test/start')
      },
      {
        path: '/examTest',
        name: 'examTest',
        meta: {title: '考试中'},
        component: () => import('@/view/real_test/examTest')
      },
      {
        path: '/setans',
        name: 'setans',
        meta: {title: '科一科四设置'},
        component: () => import('@/view/userInfo/setans')
      },
      {
        path: '/error',
        name: 'error',
        meta: {title: '错题练习'},
        component: () => import('@/view/info/error')
      },
      {
        path: '/chapter',
        name: 'chapter',
        meta: {title: '章节练习'},
        component: () => import('@/view/info/chapter')
      },
      {   // 模拟考试
        path: '/mock',
        name: 'mock',
        meta: {title: '模拟考试'},
        component: () => import('@/view/info/mock')
      },
      {   // 市区选择
        path: '/map',
        name: 'map',
        meta: {title: '市区选择'},
        component: () => import('@/view/info/map')
      },
      {   // 招生海报
        path: '/recruit',
        name: 'recruit',
        meta: {title: '教练工具'},
        component: () => import('@/view/info/recruit')
      },
      {   // 购买VIP
        path: '/CartVip',
        name: 'CartVip',
        meta: {title: '开通VIP'},
        component: () => import('@/view/info/CartVip')
      },
      {   // 我的名片
        path: '/postcard',
        name: 'postcard',
        meta: {title: '我的名片'},
        component: () => import('@/view/info/postcard')
      },
      {   // 招生海报名片
        path: '/stucard',
        name: 'stucard',
        meta: {title: '招生海报'},
        component: () => import('@/view/info/stucard')
      },
      {   // 颁发奖状
        path: '/editAward',
        name: 'editAward',
        meta: {title: ''},
        component: () => import('@/view/tools/editAward')
      },
      {   // 招聘求职
        path: '/job',
        name: 'job',
        meta: {title: '招聘求职'},
        component: () => import('@/view/tools/list/index')
      },
      {   // 招聘编辑
        path: '/jobEdit',
        name: 'jobEdit',
        meta: {title: '招聘编辑'},
        component: () => import('@/view/tools/edit/jobEdit')
      },
      {   // 招聘详情
        path: '/jobDetail',
        name: 'jobDetail',
        meta: {title: '招聘详情'},
        component: () => import('@/view/tools/detail/jobDetail')
      },
      {   // 车辆转让
        path: '/car',
        name: 'car',
        meta: {title: '车辆转让'},
        component: () => import('@/view/tools/list/index')
      },
      {   // 车辆详情
        path: '/carDetail',
        name: 'carDetail',
        meta: {title: '车辆详情'},
        component: () => import('@/view/tools/detail/carDetail')
      },
      {   // 车辆编辑
        path: '/carEdit',
        name: 'carEdit',
        meta: {title: '车辆编辑'},
        component: () => import('@/view/tools/edit/carEdit')
      },
      {   // 委托培训
        path: '/train',
        name: 'train',
        meta: {title: '委托培训'},
        component: () => import('@/view/tools/list/index')
      },
      {   // 委托详情
        path: '/trainDetail',
        name: 'trainDetail',
        meta: {title: '委托详情'},
        component: () => import('@/view/tools/detail/trainDetail')
      },
      {   // 委托编辑
        path: '/trainEdit',
        name: 'trainEdit',
        meta: {title: '委托编辑'},
        component: () => import('@/view/tools/edit/trainEdit')
      },
      {   // 我的招聘
        path: '/myjob',
        name: 'myjob',
        meta: {title: '招聘求职'},
        component: () => import('@/view/tools/list/myJob')
      },
      {   // 我的车辆
        path: '/mycar',
        name: 'mycar',
        meta: {title: '车辆转让'},
        component: () => import('@/view/tools/list/myJob')
      },
      {   // 我的委托
        path: '/mytrain',
        name: 'mytrain',
        meta: {title: '委托培训'},
        component: () => import('@/view/tools/list/myJob')
      },
      {   // 颁发奖状二维码
        path: '/awardShare',
        name: 'awardShare',
        meta: {title: ''},
        component: () => import('@/view/tools/awardShare')
      },
      {   // 教练修改信息
        path: '/means',
        name: 'means',
        meta: {title: '教练信息'},
        component: () => import('@/view/info/means')
      },
      {
        path: '/c_avatarType',
        name: 'avatarType',
        component: () => import('@/view/tools/coach/avatarType'),
        meta: {
          title: '招生头像',
        },
      },
      {
        path: '/c_tempList',
        name: 'tempList',
        component: () => import('@/view/tools/coach/tempList'),
        meta: {
          title: '招生头像',
        },
      },
      {
        path: '/c_textMaking',
        name: 'textMaking',
        component: () => import('@/view/tools/coach/textMaking'),
        meta: {
          title: '招生头像',
        },
      },
      {
        path: '/c_makingcom',
        name: 'makingCom',
        component: () => import('@/view/tools/coach/makingCom'),
        meta: {
          title: '招生头像',
        },
      },
      {   // 教练分享
        path: '/coachShare',
        name: 'coachShare',
        meta: {title: '分享'},
        component: () => import('@/view/info/coachShare')
      },
      {   // 考试结果
        path: '/report',
        name: 'report',
        meta: {title: '考试结果'},
        component: () => import('@/view/info/report')
      },
      {   // 科三单独二维码
        path: '/kmShare',
        name: 'kmShare',
        meta: {title: '分享'},
        component: () => import('@/view/info/kmShare')
      },
      {   // 积分商城首页
        path: '/integral',
        name: 'integral',
        meta: {title: '积分商城'},
        component: () => import('@/view/integral/index')
      },
      {   // 收货地址
        path: '/inteAdd',
        name: 'inteAdd',
        meta: {title: '收货地址'},
        component: () => import('@/view/integral/inteAdd/index')
      },
      {   // 详情
        path: '/itgDetail',
        name: 'itgDetail',
        meta: {title: '详情'},
        component: () => import('@/view/integral/itgDetail')
      },
      {   // 商品列表
        path: '/itgHistory',
        name: 'itgHistory',
        meta: {title: '兑换商品列表'},
        component: () => import('@/view/integral/itgHistory')
      },
      {   // 订单详情
        path: '/itgOrderDetail',
        name: 'itgOrderDetail',
        meta: {title: '订单详情'},
        component: () => import('@/view/integral/itgOrderDetail')
      },
      {   // 我的积分
        path: '/myIntegral',
        name: 'myIntegral',
        meta: {title: '我的积分'},
        component: () => import('@/view/integral/myIntegral')
      },
      {   // 安装列表
        path: '/dispatch',
        name: 'dispatch',
        meta: {title: '安装列表'},
        component: () => import('@/view/dispatch/index')
      },
      {   // 安装详情
        path: '/dphDetail',
        name: 'dphDetail',
        meta: {title: '安装详情'},
        component: () => import('@/view/dispatch/dphDetail')
      },
      {
        path: '/complainIndex',
        name: 'com_index',
        component: () => import('@/view/complain/index'),
        meta: {
          title: '投诉列表',
        },
      },
      {
        path: '/complainDetail',
        name: 'com_details',
        component: () => import('@/view/complain/details'),
        meta: {
          title: '投诉详情',
        },
      },
    ]
  },
  {
    path: '/update',
    name: 'update',
    mate: {},
    component: () => import('@/view/update/update')
  },
  { path: '*',
    name: 'error_404',
    meta: { hideInMenu: true },
    component: () => import('@/view/error/404')
  },
  {   // 首页导航关闭
    path: '/closeError',
    name: 'closeError',
    meta: {title: '网站已关闭', hideInMenu: true},
    component: () => import('@/view/info/closeError')
  },

  {   // 测试
    path: '/text',
    name: 'text',
    meta: {title: '测试'},
    component: () => import('@/view/text')
  }
]
