import config from '@/config'
import axios from 'axios'
import router from '@/router'
import { setToken, getToken } from '@/libs/util'
import { Notify,Dialog } from 'vant';
import checkLogin from "@/features/checkLogin";
import { axiosResponseHandle as handlePostersSetting } from '@/components/HandlePostersSetting';

export const baseUrl = (process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro)

class HttpRequest {
    constructor(baseUrl) {
        this.baseUrl = baseUrl
    }

    interceptors(instance) {
        // 请求拦截
        instance.interceptors.request.use(config => {
            return config
        }, error => {
            Notify('网络异常,请稍后重试!');
        })
        // 响应拦截
        instance.interceptors.response.use(res => {
            const { data, status } = res
            if (data.code == 200) {
                handlePostersSetting(res);
            }
            if(data.code === 401) {
                setToken('')
                checkLogin(true);
            } else if(data.code === 403){
                Dialog.alert({message: data.data.message}).then(() =>{
                    //这个可以关闭安卓系统的手机
                    document.addEventListener('WeixinJSBridgeReady', function(){ WeixinJSBridge.call('closeWindow'); }, false);
                    //这个可以关闭ios系统的手机
                    WeixinJSBridge.call('closeWindow');
                })
            } else if(data.code === 402) {
                router.push({path: "/update",query:{message: data.message,code: "test"}})
            }
            return res.data
        }, error => {
            Notify('请求异常!');
            return Promise.reject(error)
        })
    }

    request(options) {
        const instance = axios.create()
        let apiAuth = getToken()
        if (apiAuth === false) {
            options = Object.assign({
                baseURL: this.baseUrl,
                headers: {}
            }, options)
        } else {
            options = Object.assign({
                baseURL: this.baseUrl,
                headers: {
                    token: apiAuth,
                }
            }, options)
        }
        this.interceptors(instance)
        return instance(options)
    }
}

const api_axios = new HttpRequest(baseUrl)
export default api_axios
