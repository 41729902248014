import * as types from '../type.js'
import { homePage } from '../../api/theory.js'

const state = {
	error_arr: [],  //错题
	right_arr: [],   //对题
	qst_arr: null,     //题库
	grades: null,   //题目答题信息
	qst_index: null,    //当前题目下标
	qst_info: null,       //当前题目的信息
	home_default: {},   //首页数据
	car: {},   //车数据
	km: {},    //科目数据
	ball: [],
	timestamp: 0
}

const mutations = {
	[types.ERROE_ARR](state,error_arr) {
		if(error_arr) {
			state.error_arr.push(error_arr)
			sessionStorage.setItem("error_arr",JSON.stringify(state.error_arr) )
		} else {
			state.error_arr = []
			if(sessionStorage.getItem("error_arr")) {
				sessionStorage.removeItem("error_arr")
			}
		}
		
	},
	[types.RIGHT_ARR](state,right_arr) {
		if(right_arr) {
			state.right_arr.push(right_arr)
			sessionStorage.setItem("right_arr",JSON.stringify(state.right_arr) )
		} else {
			state.right_arr = []
			if(sessionStorage.getItem("right_arr")) {
				sessionStorage.removeItem("right_arr")
			}
		}
	},
	[types.QST_ARR](state,qst_arr) {
		state.qst_arr = qst_arr
		sessionStorage.setItem('qst_arr',JSON.stringify(state.qst_arr) )
	},
	[types.GRADES](state,grades) {
		state.grades = grades
		sessionStorage.setItem('grades',JSON.stringify(state.grades))
	},
	[types.QST_INDEX](state,qst_index) {
		state.qst_index = qst_index
		sessionStorage.setItem('qst_index',JSON.stringify(state.qst_index))
	},
	[types.QST_INFO](state,qst_info) {
		state.qst_info = qst_info
		sessionStorage.setItem('qst_info',JSON.stringify(state.qst_info))
	},
	[types.HOMEPAGE] (state,home_page) {
		state.home_default = home_page.default
		state.km = home_page.km
		state.car = home_page.car
		sessionStorage.setItem("zskc_km_info",JSON.stringify(state.home_default))
		sessionStorage.setItem("zskc_km",JSON.stringify(state.km))
		sessionStorage.setItem("zskc_car",JSON.stringify(state.car))
	},
	[types.BALL] (state,ball) {
		state.ball = ball
		sessionStorage.setItem("zskc_ball",JSON.stringify(state.ball))
	},
	[types.TIMESTAMP] (state,timestamp) {
		state.timestamp = timestamp
		sessionStorage.setItem("zskc_timestamp",state.timestamp)
	}
}

const actions = {
	[types.HOMEPAGE] ({commit},obj) {
		return new Promise((reslove,reject) =>{
			homePage(obj).then(res =>{
				commit(types.HOMEPAGE,res.data)
				reslove(res)
			})
		})
	}
}

export default {
	state,
	mutations,
	actions
}