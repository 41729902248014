export default {
  /**
   * @description 配置显示在浏览器标签的title
   */
  title: '掌上理论',
  /**
   * @description api请求基础路径
   */
  baseUrl: {
    // dev: 'http://api.mixv2.com/wx/',
    dev: 'http://m.txk3.sjztjky.net',
    pro: ''
  },

  theme_value : "",

  shareUrlConfig: {
    'jkt3.examphone.com': 'http://jkt3.sj.examphone.com',
    'm.examphone.com': 'http://share.m.examphone.com',
    'w.sjztjky.com': 'http://turn.w.sjztjky.net',
    'w.smjky.com': 'http://turn.w.smjky.net',
    'k3api.examphone.com': 'http://jkt3.sj.examphone.com',
    'm.txk3.sjztjky.net': 'http://share.txk3.sjztjky.net'
  },
  titleConfig: {
    'jkt3.examphone.com': '掌上理论',
    'm.examphone.com': '掌上考场理论',
    'w.smjky.com': '神马明途',
    'w.sjztjky.com': '神驾智通',
    'k3api.examphone.com': '掌上理论2',
    'm.txk3.sjztjky.net': '测试'
  },
  imgData: {
    'w.smjky.com': 'https://km3-1259338281.cos.ap-guangzhou.myqcloud.com/smmt.jpg',//神马明途
    'jkt3.examphone.com': 'https://km3-1259338281.cos.ap-guangzhou.myqcloud.com/20201228/zsll.jpg',//掌上理论
    'm.examphone.com': 'https://km3-1259338281.cos.ap-guangzhou.myqcloud.com/20201228/zskcll.jpg',//掌上考场理论
    'w.sjztjky.com': 'https://km3-1259338281.cos.ap-guangzhou.myqcloud.com/sjzt.jpeg',//神驾智通
    'k3api.examphone.com': 'https://km3-1259338281.cos.ap-guangzhou.myqcloud.com/20201228/zsll.jpg',//掌上理论2
  },
  theme: {
    original: {
      themeColor: '#139BF9',//主题色
      imgSrc: 'images',//主题图片位置
      appName: 'original',//主题class名称
      buyBtn: '#ff685c',//购买按钮颜色
      shareBtn: '#139BF9',//分享按钮颜色
      titleColor1: '#4ab5fe',//标题栏主色 
      titleColor2: '#ffb222',//标题栏副色
      lineBth: '#139BF9'
    },
    singleRow: {
      themeColor: '#41CA55',//主题色
      imgSrc: 'singleRowImg',//主题图片位置
      appName: 'singleRow',//主题class名称
      buyBtn: '#FFA800',//购买按钮颜色
      shareBtn: '#41CA55',//分享按钮颜色
      titleColor1: '#41CA55',//标题栏主色
      titleColor2: '#FFB321',//标题栏副色
      lineBth: '#41CA55'
    },
    thirdRow: {
      themeColor: 'rgba(255, 134, 0, 1)',//主题色
      imgSrc: 'thirdRowImg',//主题图片位置
      appName: 'thirdRow',//主题class名称
      buyBtn: '#ff685c',//购买按钮颜色
      shareBtn: '#139BF9',//分享按钮颜色
      titleColor1: 'rgba(255, 134, 0, 1)',//标题栏主色 
      titleColor2: '#ffb222',//标题栏副色
      lineBth: 'rgba(14, 187, 49, 1)'
    },
    fourthRow: {
      themeColor: 'rgba(114, 170, 252, 1)',//主题色
      imgSrc: 'fourthRowImg',//主题图片位置
      appName: 'fourthRow',//主题class名称
      buyBtn: 'rgba(255, 134, 0, 1)',//购买按钮颜色
      shareBtn: 'rgba(114, 170, 252, 1)',//分享按钮颜色
      titleColor1: 'rgba(114, 170, 252, 1)',//标题栏主色
      titleColor2: 'rgba(114, 170, 252, 1)',//标题栏副色
      lineBth: 'rgba(14, 187, 49, 1)'
    }
  },

  /**
   * 全局分享配置
  */
  share: {
    baseUrl: 'http://share.m.examphone.com',
    title: '教练精品合集',
    content: '邀请您一起开通精品课程',
    imgurl: 'https://km3-1259338281.cos.ap-guangzhou.myqcloud.com/20210331/share.png',    // 分享图
    imgkm1: 'https://w.examphone.com/img/km.png',      // 理论
    imgkm3: 'https://km3-1259338281.cos.ap-guangzhou.myqcloud.com/20210331/share.png',      // 科三
    xy_title: "邀请你学习科一科四文科理论",
    xy_con: "专注于科一科四教学，在线学习，早日拿证",
    km3_title: "教练精品合集",
    km3_con: "邀请您一起开通精品课程",
    imgData: "",
    kf_img: ""
  },
  pay: {
    url: location.protocol + '://' + location.host
  },
  /**
   * @description 需要加载的插件
   */
  plugin: {
  }
}

