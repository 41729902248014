import * as types from '../type.js'

const  state = {
	choicetips: [],
	cd_co_tip: [],
}

const mutations = {
	[types.CHOICETIP](state,choicetip) {
		const tips = state.choicetips.indexOf(choicetip);
		if(tips == -1) {
			if(state.choicetips.length == 3) {
				state.choicetips.splice(2,1)
			}
			state.choicetips.push(choicetip)
		} else {
			state.choicetips.splice(tips,1)
		}
	},
	[types.CD_CAOCH_TIP](state,coach_tip) {
		const tip = state.cd_co_tip.indexOf(coach_tip);
		if(tip == -1) {
			if(state.cd_co_tip.length == 3) {
				state.cd_co_tip.splice(2,1)
			}
			state.cd_co_tip.push(coach_tip)
		} else {
			state.cd_co_tip.splice(tip,1)
		}
	}
}

const actions = {
	[types.CHOICETIP]({commit},choicetip) {
		commit(types.CHOICETIP,choicetip)
	},
	[types.CD_CAOCH_TIP]({commit},coach_tip) {
		commit(types.CD_CAOCH_TIP,coach_tip)
	},
}

export default {
	state,
	mutations,
	actions
}