import axios from '@/libs/api.request'

/**
 * 标志图标分类
 * @returns {wx.RequestTask | never}
 */
export const getTcon = (data) => {
  return axios.request({
    url: 'v1/topic/top-icon',
    method: 'post',
    data
  })
}

/**
 * 资料修改
 * @returns {wx.RequestTask | never}
 */
export const siteInfo = (data) => {
  return axios.request({
    url: 'v1/site/up-info',
    method: 'post',
    data
  })
}

/**
 * 驾考信息考试页面
 * @returns {wx.RequestTask | never}
 */
export const homePage = (data) => {
  return axios.request({
    url: 'v1/topic/home-page',
    method: 'post',
    data
  })
}

/**
 * 科一科四用户个人信息
 * @returns {wx.RequestTask | never}
 */
export const mumberInfo = () => {
  return axios.request({
    url: 'v1/user/member-info',
    method: 'post',
    data: {}
  })
}

/**
 * 科一科四获取城市列表
 * @returns {wx.RequestTask | never}
 */
export const topicCity = () => {
  return axios.request({
    url: 'v1/topic/city',
    method: 'get',
    data: {}
  })
}

/**
 * 科一科四获取购买VIP类型
 * @returns {wx.RequestTask | never}
 */
export const getOrderPk = (type) => {
  return axios.request({
    url: 'v1/product/product',
    method: 'post',
    data: {
			product: type
		}
  })
}

/**
 * 科一科四下单VIP
 * @returns {wx.RequestTask | never}
 */
export const orderProd = (product, url, payment_type) => {
  return axios.request({
    url: 'v1/product/prod',
    method: 'post',
    data: {
			product: product,
			url: url,
			payment_type: payment_type
		}
  })
}

/**
 * 教练申请VIP
 * @returns {wx.RequestTask | never}
 */
export const applyvip = (product_id) => {
  return axios.request({
    url: 'v1/product/apply',
    method: 'post',
    data: {
			product_id: product_id
		}
  })
}

/**
 * 科一科四答题设置
 * @returns {wx.RequestTask | never}
 */
export const setspeech = (data) => {
  return axios.request({
    url: 'v1/topic/set-speech',
    method: 'post',
    data
  })
}

/**
 * 招生信息提交
 * @returns {wx.RequestTask | never}
 */
export const lineChange = (data) => {
  return axios.request({
    url: 'v1/user/line-change',
    method: 'post',
    data
  })
}

/**
 * 招生信息编辑
 * @returns {wx.RequestTask | never}
 */
export const lineEditor = (type) => {
  return axios.request({
    url: 'v1/user/line',
    method: 'post',
    data: {
      type: type
    }
  })
}

/**
 * 获取省市
 * @returns {wx.RequestTask | never}
 */
export const citylst = (data) => {
  return axios.request({
    url: 'v1/city/city',
    method: 'get',
    params: data
  })
}

/**
 * 招聘求职列表
 * @returns {wx.RequestTask | never}
 */
export const jobLst = (data) => {
  return axios.request({
    url: 'v1/job/list',
    method: 'get',
    params: data
  })
}

/**
 * 车辆转让列表
 * @returns {wx.RequestTask | never}
 */
export const carLst = (data) => {
  return axios.request({
    url: 'v1/car/index',
    method: 'get',
    params: data
  })
}

/**
 * 委托培训列表
 * @returns {wx.RequestTask | never}
 */
 export const trainLst = (data) => {
  return axios.request({
    url: 'v1/training/index',
    method: 'get',
    params: data
  })
}

/**
 * 招聘求职详情
 * @returns {wx.RequestTask | never}
 */
export const jobInfo = (data) => {
  return axios.request({
    url: 'v1/job/info',
    method: 'get',
    params: data
  })
}

/**
 * 车辆转让详情
 * @returns {wx.RequestTask | never}
 */
export const carInfo = (data) => {
  return axios.request({
    url: 'v1/car/info',
    method: 'get',
    params: data
  })
}

/**
 * 委托详情
 * @returns {wx.RequestTask | never}
 */
 export const trainInfo = (data) => {
  return axios.request({
    url: 'v1/training/info',
    method: 'get',
    params: data
  })
}

/**
 * 招聘求职编辑/新建
 * @returns {wx.RequestTask | never}
 */
export const jobEdit = (data) => {
  return axios.request({
    url: 'v1/job/publish',
    method: 'post',
    data: data
  })
}

/**
 * 车辆转让编辑/新建
 * @returns {wx.RequestTask | never}
 */
 export const carEdit = (data) => {
  return axios.request({
    url: 'v1/car/publish',
    method: 'post',
    data: data
  })
}

/**
 * 委托编辑/新建
 * @returns {wx.RequestTask | never}
 */
 export const trainEdit = (data) => {
  return axios.request({
    url: 'v1/training/publish',
    method: 'post',
    data: data
  })
}

/**
 * 更改职位状态
 * @returns {wx.RequestTask | never}
 */
export const jobStatus = (data) => {
  return axios.request({
    url: 'v1/job/status',
    method: 'post',
    data: data
  })
}

/**
 * 更改车辆转让状态
 * @returns {wx.RequestTask | never}
 */
export const carStatus = (data) => {
  return axios.request({
    url: 'v1/car/status',
    method: 'post',
    data: data
  })
}

/**
 * 更改委托状态
 * @returns {wx.RequestTask | never}
 */
 export const trainStatus = (data) => {
  return axios.request({
    url: 'v1/training/status',
    method: 'post',
    data: data
  })
}

/**
 * 更改委托状态
 * @returns {wx.RequestTask | never}
 */
 export const fileImage = (data) => {
  return axios.request({
    url: 'v1/file/images',
    method: 'post',
    data: data
  })
}

/**
 * 科一科四顺序答题
 * @returns {wx.RequestTask | never}
 */
export const practice = (obj) => {
  return axios.request({
    url: 'v1/topic/practice',
    method: 'post',
    data: obj
  })
}

/**
 * 科一科四顺序练习的总题数
 * @returns {wx.RequestTask | never}
 */
export const praCount = (obj) => {
  return axios.request({
    url: 'v1/topic/practice-qst-count',
    method: 'get',
    params: obj
  })
}

/**
 * 清除顺序答题记录
 * @returns {wx.RequestTask | never}
 */
export const delPractice = (obj) => {
  return axios.request({
    url: 'v1/topic/del-practice',
    method: 'post',
    data: obj
  })
}

/**
 * 科一科四模拟考试，全真考试, 错题重考，页面
 * @returns {wx.RequestTask | never}
 */
export const simulation = (km_id, car_id, type, cata_id) => {
  return axios.request({
    url: 'v1/topic/simulation',
    method: 'post',
    data: {
      km_id: km_id,
			car_id: car_id,
			type: type,
			cate_id: cata_id
    }
  })
}

/**
 * 科一科四题目下一页请求
 * @returns {wx.RequestTask | never}
 */
export const simulaPage = (exam_id, serial) => {
  return axios.request({
    url: 'v1/topic/simulation-page',
    method: 'post',
    data: {
      exam_id: exam_id,
			serial: serial,
    }
  })
}

/**
 * 科一科四错题复习-我的错题
 * @returns {wx.RequestTask | never}
 */
export const wrongTopic = (km_id, car_id) => {
  return axios.request({
    url: 'v1/topic/wrong-topic',
    method: 'post',
    data: {
      km_id: km_id,
			car_id: car_id
    }
  })
}

/**
 * 科一科四分类答题，考试成绩提交接口
 * @returns {wx.RequestTask | never}
 */
export const submitGrades = (exam_id, test_time, grades) => {
  return axios.request({
    url: 'v1/topic/submit-grades',
    method: 'post',
    data: {
      exam_id: exam_id,
			test_time: test_time,
			grades: grades
    }
  })
}

/**
 * 科一科四记为错题移除错接口，错题请求接口
 * @returns {wx.RequestTask | never}
 */
export const recordWrong = (km_id, car_id, qst_id, type, cat) => {
  return axios.request({
    url: 'v1/topic/record-the-wrong',
    method: 'post',
    data: {
      km_id: km_id,
			car_id: car_id,
			qst_id: qst_id,
			type: type,
			cat: cat
    }
  })
}

/**
 * 科一科四 => 对题请求接口
 * @returns {wx.RequestTask | never}
 */
export const rightLst = (data) => {
  return axios.request({
    url: 'v1/topic/record-right',
    method: 'post',
    data: data
  })
}

/**
 * 科一科四记为添加或删除排除的题目
 * @returns {wx.RequestTask | never}
 */
export const ruleOut = (km_id, car_id, qst_id, cat) => {
  return axios.request({
    url: 'v1/topic/to-rule-out',
    method: 'post',
    data: {
      km_id: km_id,
			car_id: car_id,
			qst_id: qst_id,
			cat: cat
    }
  })
}

/**
 * 科一科四我的收藏-排除的题
 * @returns {wx.RequestTask | never}
 */
export const topicOut = (km_id, car_id) => {
  return axios.request({
    url: 'v1/topic/rule-out',
    method: 'post',
    data: {
      km_id: km_id,
			car_id: car_id
    }
  })
}

/**
 * 科一科四真实考场
 * @returns {wx.RequestTask | never}
 */
export const thewhole = (obj) => {
  return axios.request({
    url: 'v1/topic/the-whole',
    method: 'post',
    data: obj
  })
}

/**
 * 科一科四满分学习和章节分类页面
 * @returns {wx.RequestTask | never}
 */
export const markChapte = (km_id, car_id, key_name) => {
  return axios.request({
    url: 'v1/topic/mark-chapter',
    method: 'post',
    data: {
      km_id: km_id,
			car_id: car_id,
			key_name: key_name
    }
  })
}

/**
 * 科一科四成绩详情
 * @returns {wx.RequestTask | never}
 */
export const paperDetails = (exam_id) => {
  return axios.request({
    url: 'v1/topic/paper-details',
    method: 'post',
    data: {
      exam_id: exam_id
    }
  })
}

/**
 * 科一科四错题课题列表
 * @returns {wx.RequestTask | never}
 */
export const wrongLst = (car_id) => {
  return axios.request({
    url: 'v1/topic/wrong',
    method: 'post',
    data: {
      car_id: car_id
    }
  })
}

/**
 * 获取积分商品列表
 * @returns {wx.RequestTask | never}
 */
export const inteGoods = (data) => {
  return axios.request({
    url: 'v1/integral/goods',
    method: 'get',
    params: data
  })
}

/**
 * 获取部分功能显示状态
 * @returns {wx.RequestTask | never}
 */
export const getShowTab = (data) => {
  return axios.request({
    url: 'v1/integral/show-tab',
    method: 'post',
    data: data
  })
}

/**
 * 获取收货地址列表
 * @returns {wx.RequestTask | never}
 */
export const getAddLst = (data) => {
  return axios.request({
    url: 'v1/integral/address-list',
    method: 'get',
    params: data
  })
}

/**
 * 设置收货地址状态
 * @returns {wx.RequestTask | never}
 */
export const setAddFind = (data) => {
  return axios.request({
    url: 'v1/integral/address-find',
    method: 'post',
    data: data
  })
}

/**
 * 获取商品详情
 * @returns {wx.RequestTask | never}
 */
export const goodDetail = (data) => {
  return axios.request({
    url: 'v1/integral/goods-details',
    method: 'post',
    data: data
  })
}

/**
 * 兑换积分商品
 * @returns {wx.RequestTask | never}
 */
export const subItgGood = (data) => {
  return axios.request({
    url: 'v1/integral/ex-change-product',
    method: 'post',
    data: data
  })
}

/**
 * 获取兑换记录/已兑换商品
 * @returns {wx.RequestTask | never}
 */
export const itgOrder = (data) => {
  return axios.request({
    url: 'v1/integral/orderlist',
    method: 'post',
    data: data
  })
}

/**
 * 获取积分记录
 * @returns {wx.RequestTask | never}
 */
export const itgRecord = (data) => {
  return axios.request({
    url: 'v1/integral/integral-record',
    method: 'post',
    data: data
  })
}

/**
 * 获取积分订单详情
 * @returns {wx.RequestTask | never}
 */
export const itgOrderDetail = (data) => {
  return axios.request({
    url: 'v1/integral/order-details',
    method: 'post',
    data: data
  })
}

/**
 * 积分订单退款
 * @returns {wx.RequestTask | never}
 */
export const refundOrder = (data) => {
  return axios.request({
    url: 'v1/integral/refund',
    method: 'post',
    data: data
  })
}

/**
 * 获取配送列表
 * @returns {wx.RequestTask | never}
 */
export const getDphList = (data) => {
  return axios.request({
    url: 'v1/integral/marki',
    method: 'post',
    data: data
  })
}

/**
 * 获取配送详情
 * @returns {wx.RequestTask | never}
 */
export const getDphOrder = (data) => {
  return axios.request({
    url: 'v1/integral/order-details',
    method: 'post',
    data: data
  })
}

/**
 * 修改配送状态
 * @returns {wx.RequestTask | never}
 */
export const setDphStas = (data) => {
  return axios.request({
    url: 'v1/integral/etc-marki',
    method: 'post',
    data: data
  })
}

/**
 * 预约安装时间
 * @returns {wx.RequestTask | never}
 */
export const instTime = (data) => {
  return axios.request({
    url: 'v1/integral/install-time',
    method: 'post',
    data: data
  })
}

/**
 * 投诉列表
 * @returns {wx.RequestTask | never}
 */
export const getConLst = (data) => {
  return axios.request({
    url: 'v1/comment/comp-list',
    method: 'post',
    data: data
  })
}

/**
 * 分享线路图
 * @returns {wx.RequestTask | never}
 */
export const dstInfo = (data) => {
  return axios.request({
    url: 'v1/lesson/dst-info',
    method: 'get',
    params: data
  })
}

/**
 * 上传定位地址
 * @returns {wx.RequestTask | never}
 */
export const locationAddress = (data) => {
  return axios.request({
    url: 'v1/user/login-log',
    method: 'post',
    data: data
  })
}

/**
 * 投诉列表
 * @returns {wx.RequestTask | never}
 */
export const comoList = (state) => {
  return axios.request({
    url: 'v1/comment/comp-list',
    method: 'post',
    data: {
      complaint_state: state,
    }
  })
}

/**
 * 投诉详情
 * @returns {wx.RequestTask | never}
 */
export const compDetail = (complaint_id) => {
  return axios.request({
    url: 'v1/comment/comp-details',
    method: 'post',
    data: {
      complaint_id: complaint_id,
    },
  })
}

/**
 * 获取城市Code
 * @returns {wx.RequestTask | never}
 */
export const getAdCode = (adcode) => {
  return axios.request({
    url: 'v1/city/s-ctiy',
    method: 'post',
    data: {
      adcode: adcode,
    },
  })
}