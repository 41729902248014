
import * as types from '../type.js'
import {mumberInfo} from '../../api/theory.js'
import wx from 'weixin-js-sdk';

const state = {
	user_info: '',
}

const mutations = {
	[types.SET_USERINFO](state,user_info) {
		state.user_info = user_info.data.info
	},
}

const actions = {
	[types.SET_USERINFO]({commit}) {
		return new Promise((reslove,reject) => {
			mumberInfo().then(res =>{
				if(res.code == 200) {
					commit(types.SET_USERINFO,res)
					reslove(res.data.info)
				}
			})
		})
	},
	
	[types.PAGE_ENTER]({commit}) {
		commit(types.PAGE_ENTER)
	},

	[types.GETLOCATIONS]({commit}) {
		wx.ready(() =>{
			wx.getLocation({
				type: 'wgs84',
				success: resp =>{

					// let ggPoint = new BMap.Point(resp.longitude,resp.latitude);
					// let convertor = new BMap.Convertor();
					// convertor.translate([ggPoint], 1, 5, (data) =>{
					// 	if(data.status == 0){
					// 		//根据经纬度获取省市区
					// 		let geoc = new BMap.Geocoder();
					// 		geoc.getLocation(data.points[0],(rs) =>{
					// 			// state. = rs.addressComponents.city
					// 			commit(types.GETLOCATIONS,rs.addressComponents.city)
					// 		});
					// 	}
				  // })

					// GPS坐标转腾讯
					var geocoder = new qq.maps.Geocoder({
						complete:function(result){
							commit(types.GETLOCATIONS,result.addressComponents.city)
						}
					});
					let coord = new qq.maps.LatLng(latitude,longitude);//传入数据（纬度,经度）

					geocoder.getAddress(coord);
				},
			})
		})
	}
}

export default {
	state,
	mutations,
	actions
}