<template>
  <div style="text-align: left;">
    <van-cell center title="分享时隐藏手机号">
      <template #right-icon>
        <van-switch
          :value="$store.state.user.shareHiddenInfo" size="24" @input="onInput()"
        />
      </template>
    </van-cell>
  </div>
</template>

<script>
import store from '@/store'

export default {
  methods: {
    onInput() {
      let status = this.$store.state.user.shareHiddenInfo ? 1 : -1
      this.$store.dispatch('getOrSetShareHidInfo', {val: status})
    }
  }
}

export const axiosResponseHandle = (response) => {
  let user = store.state.user
  if (user.userInfo.user_type != 2 || !user.shareHiddenInfo) return;
  if (/v1\/user\/line/.test(response.config.url)) {
    const allowPath = /stucard|postcard|awardShare|coachShare|downLine|LineList/.test(location.pathname);
    const { data: res } = response.data;
    if (allowPath && res) {
      //res.posters.line_name = '.';
      res.posters.mobile_phone = '.';
    }
  }
};
</script>

