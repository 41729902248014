import locationHrefReplaceSearchKey from '@/features/common/utils/locationHrefReplaceSearchKey';

const tokenKey = 'gzcd_web_ApiAuth';
let tokenTmp =sessionStorage.getItem(tokenKey);
export const tokenStorage = (val = null) => {
  if (val || val !== null) {
    tokenTmp = val || '';
    if (!tokenTmp) {
      sessionStorage.removeItem(tokenKey)
    } else {
       sessionStorage.setItem(tokenKey, tokenTmp)
    }
  }
  return tokenTmp;
};
