<template>
  <div id="Main">
    <router-view></router-view>
    <van-tabbar v-model="active" v-if="user_type < 3 && tabbar" :active-color="$theme.themeColor"
      inactive-color="#697987" @change="tabChange(active)">
      <van-tabbar-item to="/index" name="home">
        <span>首页</span>
        <template #icon="props">
          <img :src="require(`@/assets/${$theme.imgSrc}/首页${props.active ? '-o' : ''}.png`)" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/profit" name="profit" v-if="user_type > 1">
        <span>我的收益</span>
        <template #icon="props">
          <img :src="require(`@/assets/${$theme.imgSrc}/我的收益${props.active ? '-o' : ''}.png`)" />
        </template>
      </van-tabbar-item>
      <!-- <van-tabbar-item v-if="user_type >= 2 && $store.state.user.km3_show == 1" to="/line" name="line">
        <span>线路图下载</span>
        <template #icon="props">
          <img :src="require(`@/assets/${$theme.imgSrc}/线路图下载${props.active ? '-o' : ''}.png`)" />
        </template>
      </van-tabbar-item> -->
      <van-tabbar-item v-if="user_type < 2 && $store.state.user.km3_show == 1" to="/myLesson" name="myLesson">
        <span>已购买课程</span>
        <template #icon="props">
          <img :src="require(`@/assets/${$theme.imgSrc}/我的视频${props.active ? '-o' : ''}.png`)" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item v-if="user_type >= 2 && $store.state.user.km3_show == 1 && $store.state.user.km1_show == 1">
        <span>{{$store.state.user.km_type == 1 ? '转发视频' : '文科理论'}}</span>
        <template #icon="props">
          <img :src="require(`@/assets/${$theme.imgSrc}/文科理论.png`)" v-if="$store.state.user.km_type == 2"/>
          <img :src="require(`@/assets/${$theme.imgSrc}/转发视频.png`)" v-else/>
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/info" name="info">
        <span>{{$store.state.user.km_type == 1 ? '文科中心' : '个人中心'}}</span>
        <template #icon="props">
          <img :src="require(`@/assets/${$theme.imgSrc}/个人中心${props.active ? '-o' : ''}.png`)" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'Main',
  data() {
    return {
      active: this.$route.name || 'home',
      tabbar: this.$route.meta.tabbar || false,
      user_type: this.$store.state.user.userInfo.user_type,
    }
  },
  
  created() {
    this.getUser()
  },

  watch: {
    '$route.name': function () {
      this.active = this.$route.name
      this.tabbar = this.$route.meta.tabbar
      if (this.$route.name == 'line' && this.user_type >= 2) this.tabbar = false
      if (this.$route.name == 'myLesson' && this.user_type >= 2) this.tabbar = false
    },
    '$store.state.user.userInfo': function (e) {
      this.user_type = e.user_type
    }
  },

  methods: {
    ...mapActions([
      'getUserInfo'
    ]),
    getUser() {
      this.getUserInfo().then(res => {
        // this.user_type = res.data.user_type
        // alert(res.data.user_type)
        if (this.$route.name == 'line' && this.user_type >= 2) this.tabbar = false
        if (this.$route.name == 'myLesson' && this.user_type >= 2) this.tabbar = false
      })
    },
    tabChange(evnt) {
      let idx, keyS;
      if (typeof(evnt) == "number") {
        this.active = 0
        keyS = Math.random()
        idx = localStorage.getItem("km_type") == 2 ? 1 : 2
        localStorage.setItem("km_type", idx)
        this.$store.state.user.km_type = idx
        this.$router.push({path: "/index", query: { kmType: idx, keyS: keyS }})
      }
    }
  }
}
</script>
<style lang="scss" scoped>
#Main {
  .van-tabbar--fixed,
  .van-sticky--fixed {
    max-width: 750px;
    /* no */
    margin: 0 auto;
    right: 0;
  }
}
</style>
