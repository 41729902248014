import stringify from 'qs/lib/stringify';
import { isWeixin } from '@/features/weixin/utils';
import sortObj from '../common/utils/sortObj';
import { authorize } from './config'
import locationHrefReplaceSearchKey from '@/features/common/utils/locationHrefReplaceSearchKey';

export const codeStateRegExp = /[?&]code=(.*)&state=(\w+)/;

export const defaultConfig = {
  scope: 'snsapi_base',
};

export const builderAuthorizeUrl = (config) => {
  // config 作为参数动态覆盖之前的参数
  let search = {
    appid: sessionStorage.getItem('appid'),
    state: authorize.state,
    response_type: 'code',
    redirect_uri: location.href.replace(codeStateRegExp, ''),
    ...defaultConfig,
    ...(config || {}),
  }


  // 判断两者是否包含在参数里
  if (!['snsapi_base', 'snsapi_userinfo'].includes(search.scope)) {
    search.scope = 'snsapi_base';
  }
  // 检查参数排序
  search = sortObj(search);
  
  if (search.scope === 'snsapi_userinfo') {
    search.forcePopup = true;
    // search.forceSnapShot = true;
  }

  if (process.env.NODE_ENV === "development" && isWeixin) {
    authorize.uri = 'http://m.txk3.sjztjky.net/wxauthorize.php?';
  }

    return `${authorize.uri}${stringify(search)}#wechat_redirect`;
};

export const hasCodeAndState = codeStateRegExp.test(location.href) || sessionStorage.getItem('gzcd_code');

const snsapiBase = async (config) => {
  const [, code, state] = location.href.match(codeStateRegExp) || [];
  // 2 获取url 的 code & state 存缓存，并移除url的 code & state
  if (code && state) {
    sessionStorage.setItem('gzcd_code', code)
    sessionStorage.setItem('gzcd_state', state)
    if (!/android/i.test(navigator.userAgent)) {
      locationHrefReplaceSearchKey(['code', 'state']);
      if (codeStateRegExp.test(location.search)) throw '';
    } else {
      history.go(-1);
      throw '';
    }
  }
  // 3 获取缓存的 code & state 返回
  if (sessionStorage.getItem('gzcd_code') || sessionStorage.getItem('gzcd_state')) {
    let code = sessionStorage.getItem('gzcd_code')
    let state = sessionStorage.getItem('gzcd_state')
    if (!code || !state) return history.back();
    sessionStorage.removeItem('gzcd_code')
    sessionStorage.removeItem('gzcd_state')

    return { code, state };
  }

  // 1 判断是否是微信浏览器打开 重定向到微信
  if (isWeixin || !location.port) {
    // alert('判断是否是微信浏览器打开')
    location.replace(builderAuthorizeUrl(config));
  }
  throw '';
};

export default snsapiBase;

// 非静默授权获取用户信息
export const snsapiUserInfo = (config = {}) =>
  snsapiBase({ scope: 'snsapi_userinfo', ...config });
