import config from '@/config'
import jsCookie from 'js-cookie'

const { title } = config

export const TOKEN_KEY = 'gzcd_web_'

export const setToken = (token) => {
  sessionStorage.setItem(TOKEN_KEY + 'ApiAuth', token)
  // jsCookie.set(TOKEN_KEY + 'ApiAuth', token)
}

export const getToken = () => {
  const token = sessionStorage.getItem(TOKEN_KEY + 'ApiAuth')
  // const token = jsCookie.get(TOKEN_KEY + 'ApiAuth')
  if (token) {
    return token
  } else {
    return false
  }
}

// 对象转queryString
export const toQueryString = (obj) => {
  var qstring = "?"
  for (var k in obj) {
    qstring += k + "=" + obj[k] + "&"
  }
  return qstring.substring(0, qstring.length-1)
}

export const getRouteTitleHandled = (route) => {
  let router = { ...route }
  let meta = { ...route.meta }
  let title = ''
  if (meta.title) {
    if (typeof meta.title === 'function') {
      meta.__titleIsFunction__ = true
      title = meta.title(router)
    } else title = meta.title
  }
  meta.title = title
  router.meta = meta
  return router
}

/**
 * @description 根据当前跳转的路由设置显示在浏览器标签的title
 * @param {Object} routeItem 路由对象
 * @param {Object} vm Vue实例
 */
export const setTitle = (routeItem, vm) => {
  const handledRoute = getRouteTitleHandled(routeItem)
  const pageTitle = handledRoute.meta.title
  var type = sessionStorage.getItem('user_type')
  var title
  if (type == 2) {
    title = "教练"
  } else if (type == 3) {
    title = "区代"
  } else {
    title = "学员"
  }
  let resTitle = "";
  if (pageTitle == "注册分销员") {
    resTitle = pageTitle ? config.title + `- ${pageTitle}`: config.title + `- ${title}`
  } else {
    resTitle = pageTitle ? config.title + `- ${title} - ${pageTitle}`: config.title + `- ${title}`
  }
  document.title = resTitle
}
/**
 * @description  转换播放次数用+代替
 * @param {Object} routeItem 路由对象
 * @param {Object} vm Vue实例
 */
export const setNum = (value) => {
  if (value < 10000) {
    return Math.floor(value)
  } else if (value < 100000000) {
    return Math.floor(value / 10000) + "万+"
  } else {
    return Math.floor(value / 100000000) + "亿+"
  }
}